import { Atom } from ":mods";

export function createExperienceSecondPageInputs() {
  const WorkInKsaProject = Atom.Form.createInput("", {
    required: true,
  });
  const WorkInGccProject = Atom.Form.createInput("", {
    required: true,
  });
  const AreaOfProffesionDirectlyToKSA = Atom.Form.createInput("", {
    required: true,
  });
  const Actions = Atom.Form.createFormActions({
    WorkInKsaProject,
    WorkInGccProject,
    AreaOfProffesionDirectlyToKSA
  });
  return {
    Actions,
    WorkInKsaProject,
    WorkInGccProject,
    AreaOfProffesionDirectlyToKSA
  };
}
